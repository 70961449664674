.panel-items {
  padding-top: 40px;
}

.panel-text {
  width: 115px;
  z-index: 9999;
  font-weight: bold;
  color: #999;
}

.panel-index {
  z-index: 9999;
  width: 40px;
  height: 40px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
  justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  background: #ebebeb;
  color: #999;
  margin-right: 15px;
  border: 5px solid #fff;
  font-weight: bold;
}

.title:not(.last-panel):after {
  position: absolute;
  content: "";
  width: 3px;
  height: 100px;
  background: #ebebeb;
  left: 64px;
  top: 120%;
  transform: translateY(-20%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  z-index: 1;
}

.title {
  position: relative;
  padding-left: 45px;
  align-items: center;
  display: flex;
  display: -webkit-flex;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
}

.current .panel-index {
  background: #4966b1;
  color: #fff;
}
.current .panel-text {
  color: #4966b1;
}


/* REACT-DATEPICKER STYLING */
.react-datepicker {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
  overflow: hidden;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 90px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  height: 8px;
}

.react-datepicker__navigation--previous {
  border-right-color: #cbd5e0;

  &:hover {
    border-right-color: #a0aec0;
  }
}

.react-datepicker__navigation--next {
  border-left-color: #cbd5e0;

  &:hover {
    border-left-color: #a0aec0;
  }
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__header {
  border-radius: 0;
  background: #f7fafc;
}

.react-datepicker,
.react-datepicker__header,
.react-datepicker__time-container {
  border-color: #e2e8f0;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  margin: 0 1px 0 0;
  height: auto;
  padding: 7px 10px;

  &:hover {
    background: #edf2f7;
  }
}

.react-datepicker__day:hover {
  background: #edf2f7;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background: #3182ce;
  font-weight: normal;

  &:hover {
    background: #2a69ac;
  }
}


.chakra-button[data-active] {
  background: green !important;
}
